import React from 'react';
import './HighlightedBox.css'; // make sure to create this CSS file

const HighlightedBox = ({ image, children }) => {
    return (
        <div className="highlighted-box">
            <img src={image} alt="Content" className="highlighted-image" />
            <div className="highlighted-text">
                {children}
            </div>
        </div>
    );
};

export default HighlightedBox;
