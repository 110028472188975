import React from 'react';
import './Footer.css'; // Importing the CSS for the footer

// Import the library
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Import specific icons
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
    return (
        <footer className="footer">
            <div><p>Visit our Facebook Page or Email us<br/>to get started!</p></div>
            <div className="footer-icons">
                <a href="https://www.facebook.com/cleansewithlove" target="_blank" rel="noopener noreferrer" className="icon color3">
                    <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a href="mailto:connect@cleansewithlove.com" className="icon color3">
                    <FontAwesomeIcon icon={faEnvelope} />
                </a>

            </div>
            <div><p><a href="/terms_and_conditions.pdf" target='_blank'>Terms and Conditions</a></p></div>
        </footer>
    );
};

export default Footer;
