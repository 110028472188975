import React from 'react'
import './Page.css'
import cover from './Images/cover.png'
import bonny from './Images/Bonny.jpg'
import logo from './Images/logo.jpg'
import HighlightedBox from './HighlightedBox'
import Footer from './Footer'

function Page() {
  return (

    <div className="container">

      <div style={{ backgroundImage: `url(${cover})` }} className='cover-image border border-3 '>


        <div class="text">Fully supported<br />28-Day Cleanse Program</div>

        <img src={logo} alt="Cleanse with love logo" className="logo" />

      </div>



      <div className="row">
        <div className="col-md-8 fonts pt-2 ">
          {/*left-side content goes here */}
          <h1>Cleanse with Bonny Swanepoel</h1>
          <p>
            Bonny is a Nutrition Health Coach, Kinesiologist, Fitness Coach and avid Cyclist for over 35 years.
            For more than two decades she has competed consistently and successfully in road cycling, mountain biking,
            triathlons and road running. She played field hockey at an international level.
            One of her greatest sporting achievements in the recent past is to have
            completed eight successive grueling Cape Epic mountain bike stage races - and enjoyed it!

          </p>
          <p>
            Bonny believes that the route to excellent health and fitness is always multi-faceted and should
            be approached holistically.  Her passion, experience and knowledge qualifies her to assist her clients'
            weight struggles, fitness and health issues. Bonny understands her client's struggles and frustrations.
            Most importantly, she realizes the value of support and the importance of somebody who really listens.
            We do not offer miracles, but we do offer community; the battle of the bulge is often very lonely.
          </p>
          <p>The advantage of this program is the community that is created. Not only do you have full support for 28 days, you are also part of a group who shares, interact and support each other. Nevertheless, your results will depend on your own commitment.  Accountability is key.</p>
          <p>
            This program starts with four semi-fasting days followed by a plan consisting of natural plant-based wholefoods:
            no sugar, caffeine, alcohol or processed foods containing preservatives.
            You will receive a day-to-day eating plan, recipes and advice. </p>

          <p class="black_block py-3 px-4">Cleansing means getting rid of toxins resulting in lots of energy and increased levels of healing and repair in the body. Yes, this may lead to weight loss.</p>



        </div>
        <div className="col-md-4">
          {/* right-side content goes here */}
          <div className='grey mt-3'>

            <div className='mb-3 d-flex align-items-center justify-content-center border border-3'>
              <div className='py-4 px-5 fonts'>
                <h3>Our clients rate us:</h3>

                <p>Amazing program, life changing, would definitely recommend anyone who is serious about improving their life. Bonny’s wisdom and kindness is what makes the difference!!!<br /> <em>Margaux from Johannesburg, South Africa</em></p>

                <p>After much procrastination, in 2020 I took the bold step and joined Bonny in her 28-day cleanse. I  never looked back. It was not easy at first, but her guidance and support kept me going. The results were unbelievable, I  really felt envigorated and good about myself. Being part of a group made the difference. Highly recommended. <br /> <em>Annette from Johannesburg, South Africa</em></p>

              </div>
            </div>

          </div>

        </div>

      </div>

      <div className="row">
        <div className="col-md-8 fonts pt-2 ">



          <HighlightedBox image={bonny}>
            <p>Bonny developed this program from her own lifelong healthy relationship with nutrition.<br />
              <a href="https://bonswanepoel.co.za/" target="_blank" rel="noreferrer">https://bonswanepoel.co.za/</a></p>
            <p>This is a WIN-WIN situation for everyone!</p>

          </HighlightedBox>
        </div>
        <div className="col-md-4 fonts pt-2 ">
          <div className='white'>
            <div className='d-flex align-items-center justify-content-center'>
              <div className=' fonts p-3 d-flex flex-column align-items-center justify-content-center'>


                <p class="white py-3 px-4">
                  So why not connect with us
                  now on Facebook
                  and ensure the success of
                  your health & wellbeing goals  &ndash; you
                  will thank us.  <br></br> <br></br>
                  And when you write to us, be
                  sure to ask about our
                  <span class="color1bright"> special offer</span>.
                </p>

              </div>
            </div>

          </div>
        </div>
      </div>
      <Footer />
    </div>

  )
}

export default Page
